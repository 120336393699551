import addRocEventHandler from 'shared/util/events/rocEventUtils';
import HawkSearch, { PageType } from './tracking';

export default function setup() {
	addRocEventHandler('hawk-landing-page-viewed', () => {
		HawkSearch.Tracking.track('pageload', {
			pageType: PageType.landing,
			uniqueId: '',
		});
	});

	addRocEventHandler('hawk-search-tracking', ({ trackingId, searchType }) => {
		if (!trackingId) {
			// do not report search unless there is tracking id from hawk
			return;
		}
		HawkSearch.Tracking.track('searchtracking', {
			trackingId: trackingId,
			typeId: searchType,
		});
	});

	addRocEventHandler('hawk-autocomplete-click', ({ keyword, suggestType, itemName, url }) => {
		HawkSearch.Tracking.track('autocompleteclick', {
			keyword: keyword,
			suggestType: suggestType,
			itemName: itemName,
			url: url,
		});
	});

	addRocEventHandler('hawk-recommendation-click', ({ itemIndex, requestId, uniqueId, widgetGuid }) => {
		HawkSearch.Tracking.track('recommendationclick', {
			uniqueId: uniqueId,
			itemIndex: itemIndex,
			requestId: requestId,
			widgetGuid: widgetGuid,
		});
	});
}
